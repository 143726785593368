import React, { useState } from 'react';
import './Candidate.css'; // Add any custom styles here
import { postApiCall } from '../utils/commonFunctions';
import axios from 'axios';
import toast from 'react-hot-toast';

export const Candidate = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        experience: '',
        post: '',
        city: '',
        area: '',
        resume: null,
        qualification: '',
        specialization: '',
        otherSpecialization: '', // For the "Other" specialization input
        certifications: '',
        key_skills: ''
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone' && value.length > 10) {
            return;
        }
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            resume: e.target.files[0]
        });
    };
    const base_url = process.env.REACT_APP_BASE_URL;
    // const base_url = "https://31a1-2409-40c2-1022-5da7-60f8-6c0b-14e-89f7.ngrok-free.app/api";

    const validate = () => {
        let tempErrors = {};
        let isValid = true;

        // Email Validation
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = "Email is invalid.";
            isValid = false;
        }

        // Phone Validation (Assuming 10 digit number)
        if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
            tempErrors.phone = "Phone number must be 10 digits.";
            isValid = false;
        }

        // Experience Validation
        if (!formData.experience) {
            tempErrors.experience = "Experience is required.";
            isValid = false;
        }

        // Required Fields Validation
        if (!formData.firstName) {
            tempErrors.firstName = "First name is required.";
            isValid = false;
        }

        if (!formData.lastName) {
            tempErrors.lastName = "Last name is required.";
            isValid = false;
        }

        if (!formData.resume) {
            tempErrors.resume = "Resume is required.";
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) {
            return;
        }
        setLoading(true);

        const formDataForFile = new FormData();
        formDataForFile.append('file', formData.resume);

        let uploadedResumePath = null;

        try {
            const fileUploadResponse = await axios.post(`${base_url}/uploadfile`, formDataForFile, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (fileUploadResponse.status === 201) {
                uploadedResumePath = fileUploadResponse.data.data.filePath;  // Get the file path of the uploaded resume

                const finalFormData = {
                    ...formData,
                    resume: uploadedResumePath,
                };

                const response = await postApiCall('/candidateform', finalFormData);

                if (response.data.status === 201) {
                    // Reset form after successful submission
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        experience: '',
                        post: '',
                        city: '',
                        area: '',
                        resume: null,
                        qualification: '',
                        specialization: '',
                        otherSpecialization: '',
                        certifications: '',
                        key_skills: ''
                    });
                    toast.success('Form Submitted Successfully!');
                } else if (response.data.status === 409) {
                    setErrors(response.data.errors); // Set validation errors received from the server

                    // Delete the uploaded file if form submission fails
                    await deleteFile(uploadedResumePath);
                } else {
                    toast.error("Failed to submit candidate form.");

                    // Delete the uploaded file if form submission fails
                    await deleteFile(uploadedResumePath);
                }
            } else {
                toast.error('File upload failed. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error.message);
            toast.error('An error occurred. Please try again.');

            // Delete the uploaded file if any error occurs
            if (uploadedResumePath) {
                await deleteFile(uploadedResumePath);
            }
        } finally {
            setLoading(false);  // Enable the button once the API call is done
        }
    };

    // Function to delete the uploaded file
    const deleteFile = async (filePath) => {
        try {
            const response = await axios.post(`${base_url}/deletefile`, { filePath });
            if (response.status === 200) {
                console.log('File deleted successfully');
            } else {
                console.error('Failed to delete the file');
            }
        } catch (error) {
            console.error('Error deleting the file:', error.message);
        }
    };

    return (
        <>
            <section className="about-section mt-5 pt-3">
                <div className="overlay">
                    <div className="container d-flex align-items-center text-white" style={{ height: '50vh' }}>
                        <div>
                            <h3 className="mb-4">Contact Us</h3>
                            <h1 className="display-4 fw-medium">We’d love to hear from you.</h1>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container my-5">
                <h2 className="text-center mb-4">Candidate Application Form</h2>
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="firstName" className="form-label">First Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.firstName && <small className="text-danger">{errors.firstName}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="lastName" className="form-label">Last Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.lastName && <small className="text-danger">{errors.lastName}</small>}
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.email && <small className="text-danger">{errors.email}</small>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="phone" className="form-label">Phone</label>
                            <input
                                type="number"
                                className="form-control"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.phone && <small className="text-danger">{errors.phone}</small>}
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="post" className="form-label">Looking for Post or Field</label>
                            <input
                                type="text"
                                className="form-control"
                                id="post"
                                name="post"
                                value={formData.post}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="city" className="form-label">City</label>
                            <input
                                type="text"
                                className="form-control"
                                id="city"
                                name="city"
                                value={formData.city}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="area" className="form-label">Area</label>
                            <input
                                type="text"
                                className="form-control"
                                id="area"
                                name="area"
                                value={formData.area}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="experience" className="form-label">Years of Experience</label>
                        <input
                            type="number"
                            className="form-control"
                            id="experience"
                            name="experience"
                            value={formData.experience}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.experience && <small className="text-danger">{errors.experience}</small>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="qualification" className="form-label">Qualification</label>
                        <select
                            className="form-control"
                            id="qualification"
                            name="qualification"
                            value={formData.qualification}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select Qualification</option>
                            <option value="PHD">PHD</option>
                            <option value="Post Graduate">Post Graduate</option>
                            <option value="Graduate">Graduate</option>
                            <option value="Under Graduate">Under Graduate</option>
                            <option value="12th">12th</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="specialization" className="form-label">Specialization</label>
                        <select
                            className="form-control"
                            id="specialization"
                            name="specialization"
                            value={formData.specialization}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select Specialization</option>
                            <option value="MCA">MCA</option>
                            <option value="MSC">MSC</option>
                            <option value="MBA">MBA</option>
                            <option value="BCS">BCS</option>
                            <option value="B.Com">B.Com</option>
                            <option value="BE">BE</option>
                            <option value="BTECH">BTECH</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                    {formData.specialization === 'Other' && (
                        <div className="mb-3">
                            <label htmlFor="otherSpecialization" className="form-label">Please Specify Other Specialization</label>
                            <input
                                type="text"
                                className="form-control"
                                id="otherSpecialization"
                                name="otherSpecialization"
                                value={formData.otherSpecialization}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    )}

                    <div className="mb-3">
                        <label htmlFor="certifications" className="form-label">Certifications</label>
                        <input
                            type="text"
                            className="form-control"
                            id="certifications"
                            name="certifications"
                            value={formData.certifications}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="key_skills" className="form-label">Key Skills</label>
                        <input
                            type="text"
                            className="form-control"
                            id="key_skills"
                            name="key_skills"
                            value={formData.key_skills}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="resume" className="form-label">Upload Resume</label>
                        <input
                            type="file"
                            className="form-control"
                            id="resume"
                            name="resume"
                            onChange={handleFileChange}
                            accept=".pdf, .doc, .docx"
                            required
                        />
                        {errors.resume && <small className="text-danger">{errors.resume}</small>}
                    </div>

                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit Application'}
                    </button>

                </form>
            </div>
            <footer className="footer bg-light text-center py-3">
                <p>FirstStep Service © Copyright 2024</p>
            </footer>
        </>
    );
};
