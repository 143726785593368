import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { postApiCall } from '../utils/commonFunctions'; // Assuming this is your API utility function

const Current = () => {
    const [selectedLocation, setSelectedLocation] = useState("All Job Location");
    const [jobListings, setJobListings] = useState([]); // State to hold job listings
    const [locations, setLocations] = useState([]); // State to hold locations
    const [currentPage, setCurrentPage] = useState(1); // State to track the current page
    const [totalPages, setTotalPages] = useState(1); // State to track the total number of pages
    const [itemsPerPage] = useState(10); // Number of items per page

    // Fetch job locations from a separate API
    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const response = await postApiCall('/jobs/unique-locations', {});
                console.log('API Response:', response);
            
                // Validate response structure
                if (response.status === 200 && response.data.status === 200) {
                    setLocations(response.data.data); // Assuming 'data' contains the locations
                } else {
                    console.error('Failed to fetch job locations', response.data || response);
                }
            } catch (error) {
                console.error('Error fetching job locations:', error.message);
                if (error.response) {
                    console.error('Server Response:', error.response.data); // Check API response
                } else if (error.request) {
                    console.error('No Response:', error.request); // Check for network errors
                } else {
                    console.error('Request Error:', error.message); // Other issues
                }
            }
            
        };

        fetchLocations(); // Fetch locations on component mount
    }, []);

    // Fetch job listings from API with pagination and filtering
    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await postApiCall('/jobs/index', {
                    page: currentPage, // Send current page to API
                    limit: itemsPerPage, // Send items per page to API
                    search: selectedLocation !== "All Job Location" ? selectedLocation : "", // Send location to API
                });
                if (response.data.status === 200) {
                    setJobListings(response.data.data); // Assuming your API returns the job data in 'data'
                    setTotalPages(response.data.pagination.total_pages); // Assuming pagination info is in the 'pagination' field
                } else {
                    console.error('Failed to fetch job listings');
                }
            } catch (error) {
                console.error('Error fetching job listings:', error);
            }
        };

        fetchJobs(); // Fetch jobs on component mount and when page or location changes
    }, [currentPage, selectedLocation]);

    const handleSelect = (location) => {
        setSelectedLocation(location);
        setCurrentPage(1); // Reset to page 1 when location changes
    };

    // Pagination handler
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    // Filter job listings based on selected location
    const filteredJobs = jobListings.filter(job => {
        return selectedLocation === "All Job Location" || job.location.includes(selectedLocation);
    });

    return (
        <div>
            <section className="about-section mt-5 pt-3">
                <div className="overlay">
                    <div className="container d-flex align-items-center text-white" style={{ height: '50vh' }}>
                        <div>
                            <h1 className="display-4 fw-medium">Current openings</h1>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="row">
                    {/* Left Column: Job Listings */}
                    <div className="col-lg-10">
                        <div className="col-sm-3 dropdown mb-3">
                            <button
                                className="btn btn-outline-secondary dropdown-toggle"
                                type="button"
                                id="jobLocationDropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                {selectedLocation}
                            </button>

                            <ul className="dropdown-menu" aria-labelledby="jobLocationDropdown">
                                <li>
                                    <a className="dropdown-item" href="#" onClick={() => handleSelect("All Job Location")}>
                                        All Job Location
                                    </a>
                                </li>
                                {/* Dynamically add locations from the API */}
                                {locations.map((location, index) => (
                                    <li key={index}>
                                        <a className="dropdown-item" href="#" onClick={() => handleSelect(location.location)}>
                                            {location.location}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Job Listings */}
                        <div className="list-group mt-3">
                            {filteredJobs.map(job => (
                                <Link to={`/job-details/${job.id}`} key={job.id} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                    <div>
                                        <h5 className="mb-1">{job.jobTitle}</h5>
                                    </div>
                                    <div className="text-end">
                                        <small><i className="bi bi-geo-alt"></i> {job.location}</small>
                                        <br />
                                        <span className="text-primary">More Details &rarr;</span>
                                    </div>
                                </Link>
                            ))}
                        </div>

                        {/* Pagination Controls */}
                        <div className="row align-items-center mt-5">
                            <div className="col text-start">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                            </div>

                            <div className="col text-center">
                                <span>Page {currentPage} of {totalPages}</span>
                            </div>

                            <div className="col text-end">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                            </div>
                        </div>


                    </div>

                    {/* Right Column: Resume Form */}
                    <div className="col-lg-4">
                        {/* Uncomment if needed */}
                        {/* <div className="card p-3">
                            <h5>Not matching your skill in the current opening?</h5>
                            <p>Fill below form and send your resume</p>
                            <form>
                                <div className="mb-3">
                                    <input type="text" className="form-control" placeholder="Your Name" required />
                                </div>
                                <div className="mb-3">
                                    <input type="email" className="form-control" placeholder="Your Email" required />
                                </div>
                                <div className="mb-3">
                                    <input type="tel" className="form-control" placeholder="Contact number" required />
                                </div>
                                <div className="mb-3">
                                    <select className="form-select" required>
                                        <option>IT</option>
                                        <option>Marketing</option>
                                        <option>Sales</option>
                                        <option>Other</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <input type="file" className="form-control" required />
                                </div>
                                <div className="form-check mb-3">
                                    <input className="form-check-input" type="checkbox" required />
                                    <label className="form-check-label">I agree to the terms of use.</label>
                                </div>
                                <button type="submit" className="btn btn-danger">Send Resume</button>
                            </form>
                        </div> */}
                    </div>
                </div>
            </div>

            <footer className="footer bg-light text-center py-3">
                <p>FirstStep Service © Copyright 2024</p>
            </footer>
        </div>
    );
};

export default Current;
