import React from 'react'

function Footer() {
    return (
        <footer class="main-footer text-center">
            <p>FirstStep Service © Copyright 2024</p>
        </footer>

    )
}

export default Footer