import React from 'react';
import './Corporate.css'; // Add any specific styling you may need

export const Corporate = () => {
    return (
        <>
            <section className="about-section mt-5 pt-3">
                <div className="overlay">
                    <div className="container d-flex align-items-center text-white" style={{ height: '50vh' }}>
                        <div>
                            {/* <h3 className="mb-4">Our endeavor is to offer IT Solution with a Human Touch</h3> */}
                            <h1 className="display-4 fw-medium">First Step Corporate Training</h1>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container my-5">
    <div className="row align-items-center">
        <div className="col-lg-6">
            <h2>Corporate Training</h2>
            <p>Since 2014, we have consistently delivered high-quality corporate training to our clients.</p>
            <p>Our training programs have successfully equipped professionals from various sectors, including Banking, Education, Telecom, Healthcare, Manufacturing, Construction, and more.</p>
        </div>
        <div className="col-lg-6 text-center">
            <img
                src={require('../assets/corporate-training.jpg')} // Replace with your image URL
                alt="Corporate Training"
                className="img-fluid"
            />
        </div>
    </div>
</div>

            <div className='bg-light'>
                <div className="container text-center py-5">
                    <h2 className="mb-3">First Step Training Modules</h2>
                    <p className="text-muted mb-4">Training Programmes</p>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card p-4 shadow">
                                <div className="icon-container mb-3 mx-auto">
                                    <i className="bi bi-person-badge-fill fs-1"></i>
                                </div>
                                <h3>A.</h3>
                                <p>Personality Development</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card p-4 shadow">
                                <div className="icon-container mb-3 mx-auto">
                                <i className="bi bi-check2-square fs-1"></i>
                                </div>
                                <h3>B.</h3>
                                <p>Marketing & Communication</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card p-4 shadow">
                                <div className="icon-container mb-3 mx-auto">
                                    <i className="bi bi-laptop fs-1"></i>
                                </div>
                                <h3>C.</h3>
                                <p>IT Training Programme</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card p-4 shadow">
                                <div className="icon-container mb-3 mx-auto">
                                    <i className="bi bi-diagram-3-fill fs-1"></i>
                                </div>
                                <h3>D.</h3>
                                <p>Other Training Programmes</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer bg-light text-center py-3">
                <p>FirstStep Service © Copyright 2024</p>
            </footer>
        </>
    );
};
