import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const postApiCall = async (endpoint, data) => {
  try {
    console.log(baseUrl);

    const headers = {
      "Content-Type": "application/json",
    };

    const payload = data ? { ...data } : {};

    // Pass headers as the third argument
    const resp = await axios.post(`${baseUrl}${endpoint}`, payload, { headers });
    return resp;
  } catch (error) {
    return error.response || error;
  }
};
