import React from 'react';
import './About.css'; // Add any specific styling you may need

export const About = () => {
    return (
        <>
            <section className="about-section mt-5 pt-3">
                <div className="overlay">
                    <div className="container d-flex align-items-center text-white" style={{ height: '50vh' }}>
                        <div>
                            <h3 className="mb-4">About Us</h3>
                            <h1 className="display-4 fw-medium">First Step Career Experts</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="company-summary-section py-5">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-8">
                <h2 className="company-summary-title">About Our Company</h2>
                <p>
                    First Step is dedicated to delivering tailored services that address the unique business needs of our clients. With over 10 years of industry experience and specialized expertise, we are well-equipped to tackle even the most demanding challenges.
                </p>
                <p>
                    The driving force behind First Step is our directors, who bring more than 10 years of IT industry experience and have held key leadership roles in prominent listed IT companies.
                </p>
                <p>
                    We firmly believe that having the right talent is essential for a business to operate at its best. We understand that people are invaluable assets, and finding the right individuals is crucial for achieving operational excellence.
                </p>
                <p>
                    Our services are broadly categorized into contract staffing, permanent placements, and corporate training & services.
                </p>
                <h2 className="company-summary-title">Why Choose First Step?</h2>
                <p>
                    Established in 2014, we have steadily built a strong and diverse candidate portfolio that stands out in the industry.
                </p>
                <p>
                    Our extensive database and innovative recruitment strategies, combined with an experienced and well-trained team with in-depth domain knowledge, enable us to maintain exceptional quality standards.
                </p>
            </div> */}
                        <div className="col-lg-8">
                            <h2 className="company-summary-title">About First Step Recruitment</h2>
                            <p>
                                First Step Recruitment is committed to connecting businesses with top talent tailored to meet their unique needs. With over 10 years of expertise in recruitment and staffing, we have the knowledge and resources to help clients find the right individuals for even the most challenging roles.
                            </p>
                            <p>
                                The foundation of First Step Recruitment is our leadership team, bringing extensive industry experience and having held key positions in well-known IT organizations.
                            </p>
                            <p>
                                We believe that people are a company’s most valuable asset, and finding the right talent is essential to achieving operational excellence.
                            </p>
                            <p>
                                Our core services include contract staffing, permanent placements, and corporate training.
                            </p>
                            <h2 className="company-summary-title">Why Choose First Step Recruitment?</h2>
                            <p>
                                Since our establishment in 2014, we have developed a diverse and high-quality candidate portfolio, making us a standout in the recruitment industry.
                            </p>
                            <p>
                                Leveraging our extensive database, innovative recruitment strategies, and a well-trained team with in-depth industry expertise, we are dedicated to providing clients with exceptional talent solutions.
                            </p>
                        </div>

                        {/* <div className="col-lg-4">
                <img src={require('../assets/About.jpg')} alt="About Us" className="img-fluid rounded" />
            </div> */}
                    </div>
                </div>
            </section>


            <div className="animated-banner text-center mb-4">
                <h1 className="animated-text">Connecting People for over 10+ Years</h1>
            </div>

            <footer className="footer bg-light text-center py-3">
                <p>FirstStep Service © Copyright 2024</p>
            </footer>
        </>
    );
};
